// user.js

export default {
    state: {
        token: null,
        // 其他用户相关的状态...
    },
    actions: {
        resetToken(store) {
            // 重置 token 的逻辑
            store.set('user.token', null);
            return Promise.resolve();
        },
        // 其他用户相关的 actions...
    },
    // 如果需要，你也可以添加 mutations 和 getters
    mutations: {
        // 直接修改 state 的方法
    },
    getters: {
        // 计算属性
    }
};
