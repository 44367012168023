import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import layout from "./layout";
import store from "./store";
import "./assets/css/global.scss";
import "swiper/css/swiper.css";
import "./assets/css/swiper.css";
import 'element-ui/lib/theme-chalk/index.css';
import Title from '@/components/title/title'
import "@/assets/font/fonticon/iconfont.css";

import { Dialog } from 'element-ui';
Vue.config.productionTip = false;
// 引入 Vconsole
import Vconsole from 'vconsole'
import "src/assets/js/jquery.min.js";
import "src/assets/js/jquery.waypoints.min.js";
import "src/assets/js/dx.js";
import { data_title } from "@/utils/config"

import VueVideoPlayer from 'vue-video-player'
import EZUIKit from "ezuikit-js";
import ElementUI from "element-ui";

Vue.use(ElementUI);
Vue.use(EZUIKit);
Vue.use(VueVideoPlayer)

import { _set_Arry } from '@/utils/index.js'
Vue.prototype._set_Arry = _set_Arry
Vue.prototype.data_title = data_title

import { socket } from './socket.js';
Vue.use(socket);
Vue.prototype.$socket = socket;
// 所有环境均使用
// new Vconsole()

// 在 test 环境才使用
// process.env.NODE_ENV === 'development' ? new Vconsole : ''
Vue.component('my-title', Title)

Vue.use(Dialog)
Vue.use(layout);
Vue.use(store);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
