import request from '@/utils/request'
//登录
export function login_Api(params) {
    return request({
        url: '/api/dataCenter/doLogin',
        method: 'get',
        params,
    })
}
//获取配置信息
export function getConfig_Api(params) {
    return request({
        url: '/api/dataCenter/configList' + params,
        method: 'get',
    })
}

// 浙丽淘
export function zlt_Api(params) {
    return request({
        url: '/api/dataCenter/taoProductList' + params,
        method: 'get',
    })
}
//新品种推广
export function xptg_Api(params) {
    return request({
        url: '/api/dataCenter/newProductList' + params,
        method: 'get',
    })
}
//专家库
export function zjk_Api(params) {
    return request({
        url: '/api/dataCenter/expertList' + params,
        method: 'get',
    })
}
//监督评价
export function jd_Api(params) {
    return request({
        url: '/api/dataCenter/adviceList' + params,
        method: 'get',
    })
}
//农资配送
export function nzps_Api(params) {
    return request({
        url: '/api/dataCenter/deliveryServeList' + params,
        method: 'get',
    })
}
//私有农机设备分类数量
export function sy_Api(params) {
    return request({
        url: '/api/dataCenter/deviceProduceNumbList' + params,
        method: 'get',
    })
}
//私有农机单位数据
export function sydw_Api(params) {
    return request({
        url: '/api/dataCenter/deviceProduceList' + params,
        method: 'get',
    })
}
//农机服务图标
export function njfw_Api(params) {
    return request({
        url: '/api/dataCenter/farmServeList' + params,
        method: 'get',
    })
}
// 金融服务数据
export function jr_Api(params) {
    return request({
        url: '/api/dataCenter/financeServeList' + params,
        method: 'get',
    })
}
//服务中心中间图标数据
export function fwzx_Api(params) {
    return request({
        url: '/api/dataCenter/mainServeList' + params,
        method: 'get',
    })
}
//保有农机数量
export function by_Api(params) {
    return request({
        url: '/api/dataCenter/deviceNumbsList' + params,
        method: 'get',
    })
}
//保有农机服务公里数
export function byfw_Api(params) {
    return request({
        url: '/api/dataCenter/deviceServeList' + params,
        method: 'get',
    })
}
//保有农机人员数量
export function byry_Api(params) {
    return request({
        url: '/api/dataCenter/userList' + params,
        method: 'get',
    })
}
//农机维修
export function njwx_Api(params) {
    return request({
        url: '/api/dataCenter/deviceRepairList' + params,
        method: 'get',
    })
}

//农机面积
export function njmj_Api(params) {
    return request({
        url: '/api/dataCenter/deviceAreaServeList' + params,
        method: 'get',
    })
}
//生产服务数据统计
export function scfw_Api(params) {
    return request({
        url: '/api/dataCenter/produceTotal' + params,
        method: 'get',
    })
}

//生产服务数据
export function scfw_Api1(params) {
    return request({
        url: '/api/dataCenter/produceList' + params,
        method: 'get',
    })
}
// 用户画像
export function yhhx_Api(params) {
    return request({
        url: '/api/dataCenter/userProduceDetail' + params,
        method: 'get',
    })
}
//气象监测
export function qxjc_Api(params) {
    return request({
        url: '/api/dataCenter/weatherServeList' + params,
        method: 'get',
    })
}
//作物主题
export function zwzt_Api(params) {
    return request({
        url: '/api/dataCenter/cropServeList' + params,
        method: 'get',
    })
}
//农产品展销数据
export function ncpzx_Api(params) {
    return request({
        url: '/api/dataCenter/farmProductList' + params,
        method: 'get',
    })
}
//农产品订单展销数据
export function ncpddzx_Api(params) {
    return request({
        url: '/api/dataCenter/farmOrderList' + params,
        method: 'get',
    })
}
//研学活动
export function yxhd_Api(params) {
    return request({
        url: '/api/notice/researchActiveList' + params,
        method: 'get',
    })
}
//订单合同
export function dtyx_Api(params) {
    return request({
        url: '/api/notice/paddyList' + params,
        method: 'get',
    })
}
//资源兑换
export function zydh_Api(params) {
    return request({
        url: '/api/dataCenter/resourceServeList' + params,
        method: 'get',
    })
}
//资源兑换用户数据
export function zydhuser_Api(params) {
    return request({
        url: '/api/dataCenter/resourceServeUserList' + params,
        method: 'get',
    })
}
//农事主题
export function nszt_Api(params) {
    return request({
        url: '/api/dataCenter/farmThemeServeList' + params,
        method: 'get',
    })
}
//设备列表
export function sblist_Api(params) {
    return request({
        url: '/api/dataCenter/deviceList' + params,
        method: 'get',
    })
}
//设备信息
export function sbxx_Api(params) {
    return request({
        url: '/api/dataCenter/getDeviceDetail' + params,
        method: 'get',
    })
}
//主题介绍
export function ztjs_Api(params) {
    return request({
        url: '/api/notice/getThemeNotice' + params,
        method: 'get',
    })
}
//虫情数据
export function cqsj_Api(params) {
    return request({
        url: '/api/dataCenter/wornList' + params,
        method: 'get',
    })
}
//监控列表
export function jklist_Api() {
    return request({
        url: '/api/dataCenter/RsLiveList',
        method: 'get',
    })
}
//监控详情
export function jkxx_Api(params) {
    return request({
        url: '/api/dataCenter/devVideoUrl/' + params,
        method: 'get',
    })
}
//监控列表 2
export function jklist2_Api() {
    return request({
        url: '/api/dataCenter/devLcVideoUrl' ,
        method: 'get',
    })
}