export const img_url ='https://endwork.lsnf.dasding.cn/app-api'
export const data_title ={
    nj4s:'农机4S服务',
    scfw:'生产服务',
    nzps:"农资配送",
    jrfw:'金融服务',
    qxjc:'气象监测',
    njfw:'农技服务',
    zydh:"资源兑换",
    jdpj:'监督评价',
}
export let token ='eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjJhMmE3MWIyLWUwNmYtNGI1MS1iOTYzLTM4NmQzNTNiZjI3MyJ9.okx2yF4xy9FC2ChiH04TeqV-YVbxfWANpwbVQxKHiTLoVva2l80kzpJpXbupbvJRXMI5wr1Ot5AGJ2Zw4PXh7g'