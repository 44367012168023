<template>
  <div class="app">
    <router-view />
  </div>
</template>
<script>
import { getConfig_Api } from "@/api/index.js";
import { getToken,setToken } from '@/utils/auth'
import urlUtils from "./utils/url";
export default {
  methods: {
    async createSocket() {
      const { io } = this.$socket;
      io.open();
      io.on("connecting", () => {
        console.log("正在连接");
      });
      io.on("connect", () => {
        console.log("连接成功");
      });
      io.on("disconnect", () => {
        console.log("断开连接");
      });
      io.on("connect_failed", () => {
        console.log("连接失败");
      });
      io.on("error", () => {
        console.log("服务器错误发生");
      });
      io.on("reconnect_attempt", () => {});
      io.on("reconnecting", () => {});
    },
    async get_router() {
      let urls = urlUtils.getParams(window.location.href);
      console.log(urls,"urlUtils.getParams(window.location.href)")
      if(!urls.token){
        // window.location.href='https://bigscreen.lsnf.dasding.cn/#/login'
        // setToken('12321121')
      }else{
        setToken(urls.token)
      }
      
      // this.$store.set("token", 'res.data');
    },
    async get_configs() {
      let res = await getConfig_Api("");
      this.$store.set("configs", res.data);
    },
  },
  created() {
    this.createSocket();
  },
  async mounted() {
    await this.get_router();
    await this.get_configs();
  },
};
</script>
