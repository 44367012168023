import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/lsnf",
  },

  {
    path: '/lsnf', //产业引用
    name: 'lsnf',
    component: () =>
      import(/* webpackChunkName: "modules/cyyy" */ "../views/lsnf/index.vue"),
  },
  {
     path: '/map', //产业引用
       name: 'map',
        component: () =>
          import( /* webpackChunkName: "modules/cyyy" */ "../views/lsnf/map.vue"),
  },
  {
      path: '/login', //产业引用
        name: 'login',
        component: () =>
        import( /* webpackChunkName: "modules/cyyy" */ "../views/login/index.vue"),
  }
];


const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.path && to.path != from.path && from.path != "/") {
    try {
      //重点：执行monitoring.js暴露出来的hashChange方法
      jk.hashChange(to.fullPath);
    } catch (err) {
      console.error("错误：未执行监控统计");
    }
  }
  next()
})

export default router;
